import request from '@/utils/request'

export function getDistributorList(keyword, skip, take) {
  return request({
    url: '/Distributor/GetDistributorList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function getAllDistributorList() {
  return request({
    url: '/Distributor/GetAllDistributorList',
    method: 'post'
  })
}

export function getAllDistributorListWithPackage() {
  return request({
    url: '/Distributor/GetAllDistributorListWithPackage',
    method: 'post'
  })
}

export function upsertDistributor(id, name) {
  return request({
    url: '/Distributor/UpsertDistributor',
    method: 'post',
    params: {
      id,
      name
    }
  })
}

export function resetDistributorSecretKey(id) {
  return request({
    url: '/Distributor/ResetDistributorSecretKey',
    method: 'post',
    params: {
      id
    }
  })
}

export function getDistributorStatistics() {
  return request({
    url: '/Distributor/GetDistributorStatistics',
    method: 'post'
  })
}

export function getStatisticsDetail(distributorId, type) {
  return request({
    url: '/Distributor/GetStatisticsDetail',
    method: 'post',
    params: {
      distributorId,
      type
    }
  })
}
