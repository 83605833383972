<template>
  <div class="container">
    <h1>分销商</h1>
    <div class="desc">提示：本页创建分销商产生的uid和密钥, 发送给开发人员, 用于接口接入。</div>
    <div class="desc">接口文档：<a href="https://ris.1dacar.com/interface"
        target="_blank">https://ris.1dacar.com/interface</a></div>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button v-if="user_type==='1'" type="primary" size="small" icon="el-icon-plus" @click="handleNew">新增分销商
        </el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="ID" label="uid" width="100" />
        <el-table-column prop="Name" label="分销商名称" />
        <el-table-column v-if="user_type==='1'" label="操作" width="250">
          <template #default="scope">
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="danger" size="mini" icon="el-icon-refresh-left"
              @click="handleRefreshSecretKey(scope.row.ID)">
              重置并查看密钥</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
    <edit :info="curItem" v-model:showDialog="showDialog" :refresh-parent="init" />
  </div>
</template>

<script>
  import {
    getDistributorList,
    resetDistributorSecretKey
  } from "@/api/distributor"

  import Edit from "./distributorEdit"

  export default {
    components: {
      Edit
    },
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0,
        showDialog: false,
        curItem: {}
      }
    },
    computed: {
      user_type() {
        return this.$store.getters.user_type;
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getDistributorList(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      },
      handleNew() {
        this.curItem = {
          ID: 0,
          Name: ""
        }
        this.showDialog = true
      },
      handleEdit(row) {
        this.curItem = {
          ID: row.ID,
          Name: row.Name
        }
        this.showDialog = true
      },
      handleRefreshSecretKey(id) {
        this.$confirm("重置密钥可能使分销商现有接口不能使用, 确定重置吗?", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.loading = true
            resetDistributorSecretKey(id)
              .then(res => {
                if (res.success) {
                  this.$alert('请妥善保管密钥: ' + res.secretKey, '重置SecretKey成功', {
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: '关闭'
                  })
                }
              })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .desc {
      font-size: 12px;
      color: #888;
      line-height: 20px;
    }

    .filter-box {
      margin-top: 20px;

      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
